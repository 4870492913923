<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.82466 6.30547L8 9.66663L12.305 6.30547M2.6391 13.6666H13.3609C14.2662 13.6666 15 12.9328 15 12.0275V4.80218C15 3.89693 14.2661 3.16309 13.3609 3.16309H2.6391C1.73385 3.16309 1 3.89694 1 4.80218L1 12.0275C1 12.9328 1.73385 13.6666 2.6391 13.6666Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  thin: {
    type: Boolean,
    default: false
  }
})
const strokeWidth = computed(() => (props.thin ? 1 : 2))
</script>
