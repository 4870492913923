<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex md:min-h-fit min-h-full justify-center text-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="transform rounded-0 bg-white p-5 my-0 text-left transition-all min-h-full w-full relative md:my-52 md:rounded-md md:w-[650px]"
              :class="isSuccess && 'pb-0'"
            >
              <AppDialogsCommonCloseButton
                class="absolute right-5 top-5 z-10"
                @click="close"
              />
              <div v-if="!isSuccess">
                <h2 class="text-base md:text-xl font-bold mb-2.5">
                  {{ dialogTitle }}
                </h2>
                <span class="text-xs md:text-sm mb-4 block">
                  {{ name }}
                </span>
                <form class="share-form" @submit.prevent="submit">
                  <AppFormInput
                    id="share-email-input"
                    v-model="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    :error="emailError"
                    class="grow"
                    :input-class="'h-11 md:rounded-r-none'"
                  />
                  <button
                    type="submit"
                    class="btn btn-primary uppercase text-xs md:rounded-l-none h-11"
                  >
                    {{ buttonText }}
                  </button>
                </form>
              </div>
              <div v-else class="flex gap-5 relative overflow-hidden h-full">
                <div class="absolute z-1 -left-2 md:-left-4">
                  <nuxt-img
                    src="/images/happy-fisherman.png"
                    format="webp"
                    class="w-[87px] md:w-44"
                    loading="lazy"
                  />
                </div>
                <div
                  class="bg-gray rounded-full w-[87px] h-[87px] md:h-44 md:w-44 shrink-0"
                />
                <div class="mr-8 md:self-center">
                  <h2 class="text-base md:text-xl font-bold mb-2.5">
                    {{ successMessage }}
                  </h2>
                  <span class="text-xs md:text-sm mb-4 block">
                    {{ name }}
                  </span>
                </div>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import { useUserStore } from '~/stores/user'
import { useAuthStore } from '~/stores/auth'

const { $toast } = useNuxtApp()

const props = defineProps({
  type: {
    type: String,
    default: 'notify-me'
  }
  // types: 'share-list', 'notify-me'
})

const { t } = useI18n()
const emits = defineEmits(['update:modelValue'])
const open = ref(false)
const name = ref('')
const code = ref(null)
const email = ref('')
const emailError = ref(null)
const isSuccess = ref(false)

const userStore = useUserStore()
const authStore = useAuthStore()

const dialogTitle = computed(() => {
  if (props.type === 'share-list') {
    return t('Поділитися списком обраного')
  } else {
    return t('Повідомити про наявність товару')
  }
})
const buttonText = computed(() => {
  if (props.type === 'share-list') {
    return t('Поділитися списком')
  } else {
    return t('Повідомити про наявність')
  }
})
const successMessage = computed(() => {
  if (props.type === 'share-list') {
    return t('Ви успішно поділилися списком обраного')
  } else {
    return t('Ви успішно підписалися на повідомлення про наявність товару')
  }
})

const close = () => {
  name.value = null
  code.value = null
  email.value = ''
  emailError.value = null
  isSuccess.value = false
  open.value = false
}

const submit = () => {
  if (!email.value.trim()) {
    emailError.value = t('Ви не ввели Email')
    return false
  }
  if (code.value) {
    return notifyAvailability()
  }
  isSuccess.value = true
}

const notifyAvailability = () => {
  return useApi().productVariants.subscribeAvailability(
    {
      productVariantCode: code.value,
      email: email.value
    },
    {
      onSuccess: res => {
        isSuccess.value = true
        $toast.success(res?.message)
      }
    }
  )
}

onMounted(() => {
  emits('update:modelValue', {
    open: v => {
      name.value = v.name
      code.value = v.code
      open.value = true
      if (authStore.loggedIn) {
        email.value = userStore.email
      }
    }
  })
})
watch(
  () => email.value,
  v => {
    if (v.trim()) {
      emailError.value = null
    }
  }
)
</script>

<style lang="postcss" scoped>
.share-form {
  @apply flex w-full md:flex-row flex-col gap-2.5 md:gap-0;
}
</style>
